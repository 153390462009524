<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Duplicate Campaign Reporting</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <div class="form-group mr-1">
                            <select v-model="filter.client_id" class="form-control">
                                <option value="" selected>-- Any Client --</option>
                                <option v-for="client in dropdownClients" :key="client._id" :value="client._id">{{client.name}}</option>
                            </select>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.product_id" class="form-control">
                                <option value="" selected>-- Any Product--</option>
                                <option v-for="product in products" :key="product._id" :value="product._id">{{product.name}}</option>
                            </select>
                        </div>
                        <button type="button" class="btn btn-primary mr-1" @click="check"><i class="fa fa-refresh" title="Check for Duplicates"></i> Check for Duplicates</button>
                        <button type="button" class="btn btn-success mr-1" v-if="canExport" @click="exportFile"><i class="fa fa-download" title="Export to File"></i> Export to File</button>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoading && !reportLoaded">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoaded && !reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <div class="row mb-12">
                                <div class="col-12 m-table">
                                    <h3>Duplicate Campaigns ({{ this.duplicateTotal }})</h3>
                                    <b-table head-variant="dark" striped hover :items="duplicates"></b-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'CampaignReport',
        props:['user'],
        components:{
        },
        data: function () {
            return {
                action:'',
                duplicates: [],
                fileName: "data.txt",
                fileData: "",
                filter: {
                    client_id: '',
                    product_id: '',
                },
                dropdownClients: [],
                clients: [],
                products: [],
                reportLoading: false,
                reportLoaded: false,
                searching: false
            }
        },
        computed:{
            duplicateTotal: function(){
                return Number(this.duplicates.length).toLocaleString();
            },
            canExport: function(){
                return (this.duplicates.length > 0);
            }
        },
        created: function(){
            this.load();
            this.loadClients();
            this.loadProducts();
            this.GetClientsList();
            window.addEventListener("keydown",this.windowListener);
        },
        destroyed: function() {
            window.removeEventListener("keydown",this.windowListener);
        },
        methods:{
            windowListener: function(e){
                if(e.keyCode === 13 && !this.searching){
                    this.check();
                }
            },
            check: function(){
                this.searching = true;
                const request = {
                    'apikey': this.$root.apikey,
                    filters: this.filter
                };

                this.reportLoading = true;
                this.reportLoaded = false;
                this.duplicates = [];

                axios.get(`${this.$root.serverUrl}/admin/reports/campaign/duplicate`,{params:request}).then(function(resp){
                    //Store the stats
                    for(let i in resp.data){
                        this.duplicates.push({
                            country: resp.data[i].country,
                            state: resp.data[i].state,
                            city: resp.data[i].city,
                            domain: resp.data[i].domain,
                            count: resp.data[i].count,
                            data: JSON.stringify(resp.data[i].data)
                        });
                    }
                    //this.present = resp.data.present;
                    this.fileName = `duplicate_export ${new Date()}`;
                    this.fileName = this.fileName.replace(/ /g,"_");
                    this.fileName = this.fileName.replace(/:/g,"_");
                    //this.createExportData();
                    this.createExportDataCsv();
                    this.reportLoading = false;
                    this.reportLoaded = true;
                    this.searching = false;
                }.bind(this)).catch(() => {
                    this.reportLoading = false;
                    this.reportLoaded = true;
                    this.searching = false;
                });
            },
            GetClientsList: function(){
                this.cities = [];
                var request = {'apikey': this.$root.apikey};

                return axios.get(`${this.$root.serverUrl}/admin/clients/dropdown-list`, {params:request}).then(function(resp){
                    //Store the stats
                    this.dropdownClients = (resp.data)? resp.data.data : [];
                }.bind(this));
            },
            loadProducts: function(){
                //Load the clients
                var request = {'apikey': this.$root.apikey};
                //this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){
                    //Store the stats
                    this.products = (resp.data && resp.data.data)? resp.data.data : [];
                    //this.$root.preloader = false;
                }.bind(this));
            },
            createExportData: function(){
                this.fileData = `
================================================================================================================================================================================================================================================
                                                                            DUPLICATE CAMPAIGNS (${this.duplicateTotal})
================================================================================================================================================================================================================================================
`;
                if(this.duplicates.length > 0){
                    for(let i in this.duplicates){
                        let line = ``;
                        let buffer = '';
                        line += (typeof this.duplicates[i].country !== "undefined")
                            ?  `${this.duplicates[i].country}  `
                            : `    `;
                        line += (typeof this.duplicates[i].state !== "undefined")
                            ? `${this.duplicates[i].state}  `
                            : `    `;
                        if(typeof this.duplicates[i].city !== "undefined"){
                            buffer = 18 - this.duplicates[i].city.length;
                            line += `${this.duplicates[i].city}`;
                            for(let j=0; j<buffer; j++){
                                line += ' ';
                            }
                        }else{
                            line += `                  `
                        }
                        buffer = 60 - this.duplicates[i].domain.length;
                        line += `${this.duplicates[i].domain}`;
                        for(let j=0; j<buffer; j++){
                            line += ' ';
                        }
                        line += `${this.duplicates[i].count}  `;
                        line += `${this.duplicates[i].data}`;
                        this.fileData += `${line}\n`;
                    }
                }

                this.fileData = encodeURIComponent(this.fileData);
                console.log(this.fileData);
                this.fileData = `data:text/plain;charset=utf-8,${this.fileData}`;
            },
            createExportDataCsv: function(){
                this.fileData = `country,state,city,domain,count\n`;
                if(this.duplicates.length > 0){
                    for(let i in this.duplicates){
                        let line = ``;
                        const country = (this.duplicates[i].country || '');
                        const state = (this.duplicates[i].state || '');
                        const city = (this.duplicates[i].city || '');
                        line += `${country},`;
                        line += `${state},`;
                        line += `${city},`;
                        line += `"${this.duplicates[i].domain}",`;
                        line += `${this.duplicates[i].count}`;
                        //line += `"${this.duplicates[i].data}"`;
                        this.fileData += `${line}\n`;
                    }
                }
                this.fileData = encodeURIComponent(this.fileData);
                this.fileData = "data:text/plain;charset=utf-8," + this.fileData;
            },
            exportFile: function(){
                const element = document.createElement('a');
                element.setAttribute('href', this.fileData);
                element.setAttribute('download', `${this.fileName}.csv`);
                element.setAttribute('target','_blank');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            },
            load: function(){
                this.$root.preloader = false;
            },
            loadClients: function(){
                //Load the clients
                var request = {'apikey': this.$root.apikey};
                //this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/campaigns/clients`, {params:request}).then(function(resp){
                    //Store the stats
                    this.clients = (resp.data && resp.data.data)? resp.data.data : [];
                    //this.$root.preloader = false;
                }.bind(this));
            },
        }
    }
</script>

<style scoped>
   .m-table{
       max-height: 400px;
       overflow-y: scroll;
   }
</style>
